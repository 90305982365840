import react, { useState, useRef, useContext, useEffect } from 'react';
import { PromptCtx } from '../context/promptContext';
import styles from './Prompt.module.css';
import samplePrompts from '../assets/prompts/python.json';

const Prompt: React.FC = () => {
  const [promptState, setPromptState] = useState('');
  const promptContext = useContext(PromptCtx);
  const placeholderText =
    'A react with typescript functional component that returns a list of names...';

  useEffect(() => {
    console.log('prompt mounted');
    if (promptContext) {
      setPromptState(promptContext?.prompt);
    }
  }, []);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleRandomPrompt = () => {
    const promptsAvailable = samplePrompts.length;
    const randPrompt = Math.floor(Math.random() * promptsAvailable);
    console.log(`rand num is ${randPrompt}`);
    if (textAreaRef) {
      textAreaRef!.current!.value = samplePrompts[randPrompt].copy;
      promptContext?.promptSetter(samplePrompts[randPrompt].copy);
    }
  };

  const promptChange = (e: react.ChangeEvent<HTMLTextAreaElement>) => {
    promptContext?.promptSetter(e.target.value);
    console.log(e.target.value);
  };

  return (
    <section>
      <label htmlFor='promptArea' className={styles.hidden}>
        Prompt to Work From:
      </label>
      <button onClick={() => handleRandomPrompt()} className={styles.btnRandom}>
        Random
      </button>
      <textarea
        className={styles.textarea}
        placeholder={placeholderText}
        ref={textAreaRef}
        id='promptArea'
        name='promptArea'
        rows={4}
        cols={50}
        defaultValue={promptState}
        onChange={(e) => promptChange(e)}
      ></textarea>
    </section>
  );
};

export default Prompt;
