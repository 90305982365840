import React, { useState } from 'react';
import Nav from './components/Nav';
import Editor from './components/Editor';
import Control from './components/Control';
import Modal from './components/Modal';
import { IPromptContext, PromptCtx } from './context/promptContext';
import { ICodeCopyContext, CodeCopyCtx } from './context/codeCopyContext';
import { IModalContext, ModalCtx } from './context/modalContext';
import styles from './App.module.css';

// firebaseSDK
// Import the functions you need from the SDKs you need
import { firebaseConfig } from './config/firebase';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [prompt, setPrompt] = useState('');
  const [codeCopy, setCodeCopy] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const promptContext: IPromptContext = {
    prompt: prompt,
    promptSetter: setPrompt,
  };

  const codeCopyContext: ICodeCopyContext = {
    codeCopy: codeCopy,
    codeCopySetter: setCodeCopy,
  };

  const modalContext: IModalContext = {
    isVisible: isModalVisible,
    modalSetter: setIsModalVisible,
  };

  return (
    <PromptCtx.Provider value={promptContext}>
      <CodeCopyCtx.Provider value={codeCopyContext}>
        <ModalCtx.Provider value={modalContext}>
          <div className={styles.container}>
            <div className={styles.promptArea}>
              <Nav />
              <Control />
            </div>
            <div className={styles.editorArea}>
              <Editor />
            </div>
          </div>
          <Modal />
        </ModalCtx.Provider>
      </CodeCopyCtx.Provider>
    </PromptCtx.Provider>
  );
}

export default App;
