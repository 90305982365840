import React, { useContext } from 'react';
import { ModalCtx } from '../context/modalContext';
import styles from './Nav.module.css';

const Nav: React.FC = () => {
  const modalContext = useContext(ModalCtx);

  return (
    <nav>
      <p className={styles.branding}>codeForge</p>
      <button
        onClick={() => modalContext?.modalSetter(!modalContext.isVisible)}
      >
        about
      </button>
    </nav>
  );
};

export default Nav;
