import react from 'react';
import styles from './Switch.module.css';
interface SwitchProps {
  mode: string;
  setMode: react.Dispatch<react.SetStateAction<string>>;
}

const Switch: React.FC<SwitchProps> = ({ mode, setMode }) => {
  return (
    <section className={styles.container}>
      <button
        className={mode === 'prompt' ? styles.active : ''}
        onClick={() => setMode('prompt')}
      >
        Prompt
      </button>
      <button
        className={mode === 'code_copy' ? styles.active : ''}
        onClick={() => setMode('code_copy')}
      >
        Code Copy
      </button>
    </section>
  );
};

export default Switch;
