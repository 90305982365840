import react, { useState } from 'react';
import styles from './Editor.module.css';
import AceEditor from 'react-ace';

// editor modes
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-rust';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-mysql';

//editor themes
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-dawn';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/theme-solarized_dark';

// additional editor tools
import 'ace-builds/src-noconflict/ext-language_tools';

export default function Editor() {
  const [editorValue, setEditorValue] = useState(`ready when you are 😀`);
  const [theme, setTheme] = useState('dawn');
  const [mode, setMode] = useState('javascript');

  function onChange(newValue: string) {
    setEditorValue(newValue);
  }

  const handleClearEditor = () => {
    setEditorValue('');
  };

  const placeholderText = `const myNameList: React.FC<string[]> = ({ namelist }) => {
  return (
    <ul>
      {namelist.map(name, index => <li key={index}>name</li>)}
    </ul>
    );
  };
  export default myNameList;`;

  const handleThemeChange = (newVal: react.ChangeEvent<HTMLSelectElement>) => {
    setTheme(newVal.currentTarget.value);
  };

  const handleModeChange = (newVal: react.ChangeEvent<HTMLSelectElement>) => {
    setMode(newVal.currentTarget.value);
  };

  return (
    <section className={styles.container}>
      <div className={styles.editorControls}>
        <label htmlFor='lang'>Choose a language:</label>
        <select
          name='lang'
          id='lang'
          defaultValue={'javascript'}
          onChange={(e) => handleModeChange(e)}
        >
          <option value='javascript'>JS</option>
          <option value='typescript'>TS</option>
          <option value='c_cpp'>C++</option>
          <option value='java'>Java</option>
          <option value='rust'>Rust</option>
          <option value='golang'>Go</option>
          <option value='python'>Python</option>
          <option value='yaml'>YAML</option>
          <option value='mysql'>MySQL</option>
        </select>

        <label htmlFor='theme'>Choose a theme:</label>
        <select
          name='theme'
          id='theme'
          defaultValue={'dawn'}
          onChange={(e) => handleThemeChange(e)}
        >
          <option value='monokai'>monokai</option>
          <option value='dawn'>dawn</option>
          <option value='terminal'>terminal</option>
          <option value='solarized_dark'>solarized_dark</option>
        </select>

        <button onClick={handleClearEditor} className={styles.clearBtn}>
          Clear
        </button>
      </div>
      <AceEditor
        placeholder={placeholderText}
        mode={mode}
        theme={theme}
        name='editor'
        width='100%'
        className={styles.primaryEditor}
        onChange={onChange}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        wrapEnabled={true}
        value={editorValue}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </section>
  );
}
