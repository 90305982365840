import react, { useState, useContext, useEffect } from 'react';
import styles from './CopyEditor.module.css';
import AceEditor from 'react-ace';
import { CodeCopyCtx } from '../context/codeCopyContext';

// Ace Editor Imports
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/ext-language_tools';

export default function CopyEditor() {
  const [editorValue, setEditorValue] = useState('');

  const codeCopyContext = useContext(CodeCopyCtx);

  function onChange(newValue: string) {
    setEditorValue(newValue);
    codeCopyContext?.codeCopySetter(newValue);
  }

  // check for existing editor text when component mounts
  useEffect(() => {
    console.log('editor mounted');
    if (codeCopyContext) {
      setEditorValue(codeCopyContext?.codeCopy);
    }
  }, []);

  const handleClearEditor = () => {
    setEditorValue('');
    codeCopyContext?.codeCopySetter('');
  };

  const placeholderText = `copy/paste reference code here...`;

  return (
    <section className={styles.container}>
      <AceEditor
        placeholder={placeholderText}
        mode='text'
        theme='terminal'
        name='editor'
        className={styles.copyEditor}
        width='40vw'
        height='550px'
        onChange={onChange}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        wrapEnabled={true}
        value={editorValue}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
      <button className={styles.clearBtn} onClick={handleClearEditor}>
        Clear
      </button>
    </section>
  );
}
