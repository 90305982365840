import React from 'react';
import CopyEditor from './CopyEditor';
import Prompt from './Prompt';

interface EditorProps {
  mode: string;
}

const PromptEditor: React.FC<EditorProps> = ({ mode }) => {
  if (mode === 'prompt') {
    return <Prompt />;
  }
  return <CopyEditor />;
};

export default PromptEditor;
