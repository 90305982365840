import React, { useContext, useLayoutEffect } from 'react';
import { ModalCtx } from '../context/modalContext';
import styles from './Modal.module.css';

const Modal: React.FC = () => {
  const modalContext = useContext(ModalCtx);

  useLayoutEffect(() => {
    // Prevent scrolling when modal is open.
    const body = document.getElementsByTagName('body');
    modalContext?.isVisible
      ? (body[0].style.overflow = 'hidden')
      : (body[0].style.overflow = 'overlay');
  }, [modalContext?.isVisible]);

  return (
    <div>
      {modalContext?.isVisible ? (
        <div
          className={styles.container}
          onClick={() => modalContext.modalSetter(!modalContext.isVisible)}
        >
          <section className={styles.modalCopy}>
            <h1>About This Site</h1>
            <p>
              I wrote this site for myself, feel free to use it as you please. As a consultant with a diversity of projects and clients, I
              sometimes have to hop from back-end to front-end, from JS to C++,
              or from React to Vue to creating Stencil components for a client's
              DLS. When I change gears, I usually need somewhere to switch off
              my javascript brain and spin up my python brain. My fingers need
              to move differently. This is my tool for that.
            </p>
            <p>
              I generally copy and paste stuff on the left, and then practice writing my code on the right. The left side toggles between a prompt or an editor window (the <em>code copy</em> area). On the right I can switch between languages mostly for code highlighting. I added a button that generates simple python finger excercises for a specific someone - use it if you are getting started with python.
            </p>
          </section>
        </div>
      ) : null}
    </div>
  );
};

export default Modal;
