import react, { useState } from 'react';
import styles from './Control.module.css';
import PromptEditor from './PromptEditor';
import Switch from './Switch';

export default function Control() {
  const [mode, setMode] = useState('prompt');

  return (
    <section className={styles.container}>
      <Switch mode={mode} setMode={setMode} />
      <PromptEditor mode={mode} />
    </section>
  );
}
